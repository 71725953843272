import React from 'react';
import PropTypes from 'prop-types';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Image from 'common/src/components/Image';
import Heading from 'common/src/components/Heading';
//import Button from 'common/src/components/Button';
import FeatureBlock from 'common/src/components/FeatureBlock';
import Container from 'common/src/components/UI/Container';
import Particles from '../../Agency/Particle';
//import DashboardWrapper, { DashboardObject } from './dashboard.style';
import DashboardWrapper, { DashboardObject } from '../ArcherRxDashboardFeatures/dashboard.style';
import DashboardObject1 from 'common/src/assets/image/archerhealth/feature/Onsite-Clinics-Raise-Benefits-Package-min.png';

const OnsiteClinicsBenefit2 = ({
  row,
  col,
  title,
  btnStyle,
  description,
  discountText,
  discountAmount,
  outlineBtnStyle,
}) => {
  /*
  const ButtonGroup = () => (
    <Fragment>
      <Button title="FREE TRAIL" {...btnStyle} />
    </Fragment>
  );
  */
  return (
    <DashboardWrapper id="banner_section">
      <Particles />
      <DashboardObject>
        <div className="dashboardWrapper">
          <Image src={DashboardObject1} alt="DashboardObject1" />
        </div>
      </DashboardObject>
      <Container>
        <Box className="row" {...row}>
          <Box className="col" {...col}>
            
            <Heading className="subtitle" as="h1" content="Raise the competitiveness of your benefits package" />
            
            <FeatureBlock   
              description={
                <Text
                  content="The number one benefit sought after by employees? You guessed it, healthcare. But traditional healthcare plans are no longer enough to attract and retain talent. Make your company stand out from the rest with a benefits package that shows you care."
                  {...description}
                />
              }
              //button={<ButtonGroup />}
            />
          </Box>
        </Box>
      </Container>
      
    </DashboardWrapper>
  );
};

OnsiteClinicsBenefit2.propTypes = {
  title: PropTypes.object,
  btnStyle: PropTypes.object,
  description: PropTypes.object,
  contentStyle: PropTypes.object,
};

OnsiteClinicsBenefit2.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
    alignItems: 'center',
  },
  col: {
    pr: '15px',
    pl: '15px',
    width: [1, '100%', '50%', '45%'],
  },
  title: {
    fontSize: ['22px', '30px', '30px', '30px', '32px'],
    fontWeight: '400',
    color: '#2CC36B',
    letterSpacing: '-0.025em',
    mb: ['20px', '15px', '15px', '20px', '25px'],
    lineHeight: '1.3',
    //maxWidth: ['100%', '400px'],
    maxWidth: ['100%'],
  },
  description: {
    fontSize: '20px',
    fontWeight: '400',
    color: '#3D636C',
    lineHeight: '1.85',
    mb: '0',
  },
  btnStyle: {
    minWidth: ['120px', '120px', '120px', '156px'],
    fontSize: ['13px', '14px'],
    fontWeight: '500',
    colors: 'primaryWithBg',
  },
};

export default OnsiteClinicsBenefit2;
