import React from 'react';
import { ThemeProvider } from 'styled-components';
import { theme } from 'common/src/theme/appclassic';
import { ResetCSS } from 'common/src/assets/css/style';
import Sticky from 'react-stickynode';
import Navbar from '../containers/AppClassic/Navbar';
import Footer from '../containers/Agency/Footer';
import GlobalStyle, {
  AppWrapper,
  ContentWrapper,
} from '../containers/AppClassic/appClassic.style';
import SEO from '../components/seo';

import Newsletter from '../containers/Agency/Newsletter';

/* Page Specific Items */
import Banner from '../containers/Agency/OnsiteClinicsBanner';
import DashboardFeatures from '../containers/AppModern/OnsiteClinicsDashboardFeatures';
import OnsiteClinicsBenefit1 from '../containers/Interior/OnsiteClinicsBenefit1';
import OnsiteClinicsBenefit2 from '../containers/AppModern/OnsiteClinicsBenefit2';
import OnsiteClinicsBenefit3 from '../containers/Interior/OnsiteClinicsBenefit3';
import Container from 'common/src/components/UI/Container';
import Heading from 'common/src/components/Heading';

export default function() {
  return (
    <ThemeProvider theme={theme}>
      <>
        <SEO title="Onsite Clinic Services for Employees" description="Archer Health can help you establish a corporate health clinic. Reduce your overall healthcare spend & promote a healthier corporate culture. Get started today!" />
        <ResetCSS />
        <GlobalStyle />

        <AppWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-active">
            <Navbar />
          </Sticky>
          <ContentWrapper>            
            <Banner />
            <DashboardFeatures />            
            <OnsiteClinicsBenefit1 />
            <OnsiteClinicsBenefit2 />
            <OnsiteClinicsBenefit3/>
            <Container>
            <div id="article-content">
              <Heading as="h2" content="How Onsite Health Clinics Help Both Employers and Employees" />
              <p>When you run a business, you know how important it is to attract and retain the most talented and experienced employees. Providing excellent occupational health options such as by having an onsite clinic is one perk that helps not only the employees, but also the employer!</p>
              <p>You and your employees can benefit from onsite health services in multiple ways. Read on for a few ways that these health centers are well worth the money businesses put into them.</p>
              <br/><br/><br/>
              <Heading as="h2" content="The Benefits of Onsite Health Clinics" />

              <p>If you have an onsite health clinic, the time and effort it takes for your employees to take care of their health are dramatically reduced. That leads to the following benefits of onsite care:</p>
              <ul>
              <li>&bull; If an employee is injured or becomes ill, he or she can get acute care treatment immediately, which will help you get your employees back to work as soon as possible.</li>
              <li>&bull; You can also make sure that the primary care services meet your expectations so your employees will stick around for the long term.</li>
              <li>&bull; You might also consider having a medication vendor -- an onsite pharmacy -- to get employees their medicine immediately.</li>
              <li>&bull; The more you focus on providing for the needs of your employees, the more they will be able to concentrate on doing their best work for your company and your customers.</li>
              </ul>

              <p>Health care benefits matter to your employees, so they should matter to employers, too! Archer Health can provide your staff with the best onsite health services possible.</p>
            </div>
            </Container>
          <Newsletter />
          <Footer />
          </ContentWrapper>
        </AppWrapper>
      </>
    </ThemeProvider>
  );
}
