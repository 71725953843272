import React from 'react';
import Fade from 'react-reveal/Fade';
import { useStaticQuery, graphql } from 'gatsby';
import Image from 'gatsby-image';
import Heading from 'common/src/components/Heading';
import Text from 'common/src/components/Text';
//import SectionWrapper, { Container,  ImageWrapper,  TextWrapper } from './aboutUs.style';
import SectionWrapper, { Container,  ImageWrapper,  TextWrapper } from '../ArcherRxAboutUs/aboutUs.style';

const OnsiteClinicsBenefit1 = () => {
 const data = useStaticQuery(graphql`
    query {
      image1: file(
        relativePath: { eq: "image/archerhealth/feature/Onsite-Clinics-Reduce-Spend-min.png" }        
      ) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
      }
    }
    `);

  const setTitle = title => {
    return { __html: title };
  };


  return (
    <SectionWrapper id="aboutUs">
      <Container>
        <ImageWrapper>
          <Fade left>
          <Image
              fluid={
                (data.image1 !== null) | undefined
                  ? data.image1.childImageSharp.fluid
                  : {}
              }
              alt={``}
              className="carousel_img"/>
          </Fade>
        </ImageWrapper>
        <TextWrapper>
          <Fade right>
            <Heading as="h1" dangerouslySetInnerHTML={setTitle(`Reduce overall healthcare spend`)} />
            <Text as="h2" content={`Our onsite clinic is designed to work in your space.`} />
            <Text content={<p>There are no expensive buildout costs here.  
            Our onsite clinic will help to reduce expensive 
            claims and overall medical care costs by decreasing 
            emergency room and urgent care visits as well as procedures.<br/><br/>
            At Archer Health, we believe that higher engagement in healthcare 
            services means happier, healthier, and more productive employees.</p>} />            
          </Fade>
        </TextWrapper>
      </Container>
    </SectionWrapper>
  );
};

export default OnsiteClinicsBenefit1;
