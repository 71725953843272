import React from 'react';
import Fade from 'react-reveal/Fade';
import { useStaticQuery, graphql } from 'gatsby';
import Image from 'gatsby-image';
import Heading from 'common/src/components/Heading';
import Text from 'common/src/components/Text';
//import SectionWrapper, { Container,  ImageWrapper,  TextWrapper,} from './aboutUs.style';
import SectionWrapper, { Container,  ImageWrapper,  TextWrapper } from '../ArcherRxAboutUs/aboutUs.style';

const OnsiteClinicsBenefit3 = () => {
  const data = useStaticQuery(graphql`
    query {
      image1: file(
        relativePath: { eq: "image/archerhealth/feature/Onsite-Clinics-Increase-Access-min.png" }        
      ) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
      }
    }
    `);

    const setTitle = title => {
      return { __html: title };
    };  return (
    <SectionWrapper id="aboutUs">
      <Container>
        <ImageWrapper>
          <Fade left>
            <Image
              fluid={
                (data.image1 !== null) | undefined
                  ? data.image1.childImageSharp.fluid
                  : {}
              }
              alt={`Increase Access`}
              className="carousel_img"
            />
          </Fade>
        </ImageWrapper>
        <TextWrapper>
          <Fade right>
            <Heading as="h1" dangerouslySetInnerHTML={setTitle(`Increase access to care for your people`)} />
            <Text content={<h2>Employees spend an average of <strong>121 minutes</strong> on each off-site medical appointment. Only a fraction of this time is 
            spent addressing actual medical concerns.</h2>} />
            <Text content={`Onsite clinics reduce 
            time lost to medical appointments by 70% while ensuring that your 
            people get the healthcare and wellness support they need. Archer 
            Health combines experienced medical staff with flexible, scalable 
            scheduling to bring you convenience, accessibility, and affordability.`} />            
          </Fade>
        </TextWrapper>
      </Container>
    </SectionWrapper>
  );
};

export default OnsiteClinicsBenefit3;
